<template>
  <div v-if="user">
    <v-app-bar
      app
      color="grey.darken4"
      clipped-left
      clipped-right
      dark
      elevation="8"
    >
      <!--            <v-app-bar-nav-icon @click="show_aside = !show_aside"></v-app-bar-nav-icon>-->
      <div class="d-flex align-center">
        <v-img
          alt="Etalon Logo"
          class="shrink mr-2 ml-2"
          contain
          src="/logo.png"
          transition="scale-transition"
          width="220"
        />
      </div>
      <v-spacer></v-spacer>
      <v-menu
        left
        bottom
        transition="scale-transition" class="mx-4"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>person</v-icon>
            {{ user.name }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout()">
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            text
          >
            <v-icon left>language</v-icon>
            {{ lang.toUpperCase() }}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="selectedLang"
            color="primary"
          >
            <v-list-item
              v-for="item in lang_list"
              :key="item"
            >
              <v-list-item-title>{{ item.toUpperCase() }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            fab
            dark
            small
          >
            <v-icon dark>
              bug_report
            </v-icon>
          </v-btn>
        </template>
        <v-list min-width="260px">
          <v-list-item link to="/dashboard/logs">
            <v-list-item-title>1C Logs</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/dashboard/rozetka-logs">
            <v-list-item-title>Rozetka Logs</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            dark
            small
            @click="getLogs"
          >
            <v-icon dark>
              wysiwyg
            </v-icon>
          </v-btn>
        </template>
        <v-card min-width="360px">
          <v-card-title>Данные актуальности</v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="system_logs">
            <div v-if="system_logs.remains_updated_at">
              Обновление остатков товара: <b>{{ system_logs.remains_updated_at | moment('DD.MM.YY HH:mm') }}</b>
            </div>
            <div v-if="system_logs.prices_updated_at">
              Обновление цен товара: <b>{{ system_logs.prices_updated_at | moment('DD.MM.YY HH:mm') }}</b>
            </div>
            <div v-if="system_logs.drop_prices_updated_at">
              Обновление drop цен: <b>{{ system_logs.drop_prices_updated_at | moment('DD.MM.YY HH:mm') }}</b>
            </div>
            <div v-if="system_logs.retail_prices_updated_at">
              Обновление розничных цен: <b>{{ system_logs.retail_prices_updated_at | moment('DD.MM.YY HH:mm') }}</b>
            </div>
            <div v-if="system_logs.products_synced_at">
              Синхронизация товаров с 1С: <b>{{ system_logs.products_synced_at | moment('DD.MM.YY HH:mm') }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        @click="show_aside = !show_aside"
      >
        <v-icon dark>
          settings
        </v-icon>
      </v-btn>
    </v-app-bar>
    <MainMenuAsideComponent :is_show="true"/>
    <SettingsMenuAsideComponent v-model="show_aside"/>
    <CategoryPropertiesAsideComponent/>
    <ProductPropertiesAsideComponent/>
    <v-main>
      <transition name="fade-transition" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
    <v-dialog
      max-width="600"
      v-model="show_new_version_dialog"
    >
      <v-card>
        <v-card-title>
          Оновлення у системі
        </v-card-title>
        <v-divider/>
        <v-card-text class="pt-4" v-html="getLogHTML()">
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="show_new_version_dialog = false">Зрозуміло</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="text-center" style="margin-top: 50vh;">
    <v-progress-circular
      :size="50"
      color="amber"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import MainMenuAsideComponent from '@/components/asides/MainMenuAsideComponent'
import SettingsMenuAsideComponent from '@/components/asides/SettingsMenuAsideComponent'
import { mapActions, mapMutations, mapState } from 'vuex'
import CategoryPropertiesAsideComponent from '@/components/asides/CategoryPropertiesAsideComponent'
import ProductPropertiesAsideComponent from '@/components/asides/ProductPropertiesAsideComponent'
import { ws } from '@/mixins/wsMixin'
import { changesLog } from '@/changeLog/changeLog'

export default {
  name: 'DashboardLayout',
  mixins: [ws, changesLog],
  components: {
    CategoryPropertiesAsideComponent,
    ProductPropertiesAsideComponent,
    SettingsMenuAsideComponent,
    MainMenuAsideComponent
  },
  data: () => ({
    show_aside: false,
    show_new_version_dialog: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('lang', ['lang', 'lang_list']),
    ...mapState('system', ['system_logs']),
    selectedLang: {
      get () {
        return this.lang
      },
      set (v) {
        this.setLang(this.lang_list[v])
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout', 'getUser']),
    ...mapMutations('lang', ['setLang']),
    ...mapActions('system', ['getLogs']),
    ...mapActions('config', ['getConfig'])
  },
  created () {
    const promise = this.getUser()
    promise.then(response => {
      this.joinChannelInetOrder()
    })
    this.getConfig()

    if (this.versionLog !== localStorage.getItem('admin_last_version')) {
      this.show_new_version_dialog = true
    }

    localStorage.setItem('admin_last_version', this.versionLog)
  }
}
</script>

<style scoped>

</style>
